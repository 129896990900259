<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      title="新增"
      :ok-button-props="{ props: { loading: submitting } }"
      @ok="handleSubmit"
    >
      <a-form
        class="simiot-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >

        <a-form-item label="卡片材质">
          <a-input
            v-decorator="['name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入卡片材质' },
                { max: 50, message: '最多50个字符' },
              ]
            }]"
          />
        </a-form-item>

        <a-form-item label="卡片尺寸" required>
          <a-row>
            <a-button
              type="primary"
              @click="addCardShape"
            >
              新增
            </a-button>
          </a-row>
        </a-form-item>
        <a-form-item
          class="card-shape"
          :wrapper-col="{ span: 15, offset: 6}"
        >
          <a-input-group compact>
            <a-form-item
              v-for="(k) in form.getFieldValue('keys')"
              :key="k"
              label=""
              :required="false"
            >
              <a-space>
                <a-input
                  @change="validateCardShapeNames"
                  style="width: 295px"
                  v-decorator="[`card_shape_names[${k}]`,{
                    normalize: $lodash.trim,
                    rules: [
                      { required: true, message: '请输入卡片尺寸' },
                      { max: 50, message: '最多50个字符' },
                      { validator: checkCardShapeName }
                    ],
                  }]"
                />
                <a-icon
                  v-show="form.getFieldValue('keys').length > 1"
                  class="dynamic-delete-button"
                  type="delete"
                  :disabled="form.getFieldValue('keys').length === 1"
                  @click="() => removeCardShape(k)"
                />
              </a-space>
            </a-form-item>
          </a-input-group>
        </a-form-item>

        <a-form-item label="说明">
          <a-textarea
            v-decorator="['description', {
              rules: [
                { max: 200, message: '最多200个字符' },
              ]
            }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { createCardType } from '@/api/card_type'

export default {
  name: 'NewCardType',
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'card_type' }),
      data: {},
      submitting: false,
      cardShapesIndex: 1
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.form.getFieldDecorator('keys', { initialValue: [0], preserve: true })
  },
  methods: {
    checkCardShapeName(rule, value, callback) {
      if (value === '') {
        callback()
        return
      }

      let nameExistCount = 0 // 名称已存在个数
      this.form.getFieldValue('card_shape_names').forEach((cardShapeName) => {
        if (cardShapeName === value) {
          nameExistCount += 1
        }
      })

      if (nameExistCount > 1) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('卡片尺寸重复')
        return
      }
      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    removeCardShape(k) {
      const { form } = this
      // can use data-binding to get
      const keys = form.getFieldValue('keys')
      // We need at least one passenger
      if (keys.length === 1) {
        return
      }

      // can use data-binding to set
      form.setFieldsValue({
        keys: keys.filter(key => key !== k)
      })

      // 删除卡片尺寸后验证其他尺寸
      this.validateCardShapeNames()
    },

    // 校验卡片尺寸
    validateCardShapeNames() {
      this.$nextTick(() => {
        this.form.getFieldValue('keys').forEach((k) => {
          // 名称存在才验证
          if (this.form.getFieldValue(`card_shape_names[${k}]`)) {
            this.form.validateFields([`card_shape_names[${k}]`], { force: true })
          }
        })
      })
    },

    addCardShape() {
      const { form } = this
      // can use data-binding to get
      const keys = form.getFieldValue('keys')
      const nextKeys = keys.concat(this.cardShapesIndex++)
      // can use data-binding to set
      // important! notify form to detect changes
      form.setFieldsValue({
        keys: nextKeys
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const cardShapeNames = values.keys.map(key => values.card_shape_names[key])
          delete values.keys
          const data = {
            ...values,
            card_shape_names: cardShapeNames
          }

          createCardType(data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }

  }
}
</script>

<style lang="less" scoped>
.card-shape{
  max-height: 280px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
  }
}
</style>
